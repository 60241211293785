import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';
import 'styles/blog.scss';
// import { Main } from 'styles/blog';

const CustomerStories = ({
  data: {
    allContentfulCustomerStory: { nodes: allPosts = [] },
  },
  location,
}) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Customer Stories | ruttl"
          description="Explore ruttl's Customer Stories and find out how our platform has simplified the feedback process for our users. "
          url="https://ruttl.com/customer-stories/"
        />

        <main className="blog-styled-main">
          <div className="blog-filters-main">
            <div className="container">
              <div className="filter-block">
                <Link to="/blog/">All Blogs</Link>
                <Link to="/product-updates/">Product Updates</Link>
                <Link to="/product-videos/">Product Videos</Link>
                <Link to="/customer-stories/" className="active">
                  Customer Stories
                </Link>
              </div>
              <h1 className="product-updates-heading">Customer Stories</h1>
            </div>
          </div>
          <section className="post-list-main product-posts">
            {/* <div className="container">
          <p className="bold post-list-main--subhead">
            Our thoughts and observations on business, partners, nutrients,
            productivity, Science, &amp; more.
          </p>
        </div> */}
            {true && (
              <div className="container">
                <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                  {allPosts.map((node) => (
                    <React.Fragment key={node.id}>
                      <ArchivePostSingle
                        link={`/customer-stories/${node.slug}/`}
                        title={node.title}
                        image={node.featuredImage.fluid}
                        excerpt={node.excerpt}
                        category={[{ name: node.tag }]}
                        date={node.createdAt}
                        // author={node.author.name}
                        // authorImage={node.author.image.fluid}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </section>
          <GetStartedCenter toggleSignup={toggleSignup} />
        </main>
      </>
    )}
  </Layout>
);

CustomerStories.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default CustomerStories;

export const pageQuery = graphql`
  query CustomerStoriesQuery {
    allContentfulCustomerStory(sort: { fields: publishDate, order: DESC }) {
      nodes {
        id
        excerpt
        title
        tag
        slug
        createdAt
        featuredImage {
          fluid(
            maxWidth: 600
            maxHeight: 456
            quality: 70
            resizingBehavior: THUMB
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
